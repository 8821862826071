import { Box, Button, Flex, FormControl, FormLabel, Heading, Image, Input, Select, Text, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { Table, Tbody, Tr, Td } from '@chakra-ui/react';
import React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ProductDetails = () => {
  const location = useLocation();
  const product = location.state && location.state.product;
  console.log(product)

 
  const toast = useToast();
  const nav=useNavigate()
  const [formData, setFormData] = useState({
      firstName: '',
      product: product ? product.name : '',
      state: '',
      price: product ? product.listingPrice : '',
      number: '',
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
  
    

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const res=await axios.post('https://main-6wsv.onrender.com/order', formData)
        
        toast({
          title: "ThankYou",
          description: "Our team will contact you",
          status: "success",
          position: "top",
        });
        nav("/")

      } catch (error) {
        toast({
          title: "Something Went Wrong",
          description: "Pleace try Again🙆‍♂️",
          status: "error",
          position: "top",
        });
      }
     
    };

  if (!product) {
    // Handle the case when there is no product data
    return <div>No product data found!</div>;
  }

  // Now you can use the 'product' variable to access the details
  return (

    <Box >
      <Image src='https://github.com/shwetra/assa/assets/104376252/da807f98-c6fb-4751-b600-da5e4754e572'/>
    <Flex  mb="100px" direction={{base:"column",lg:"row"}}>
        <Box w={{base:"90%",lg:"25%"}} m="auto">
            <Image h="400px" src={product.url}/>
        </Box>


        <Box m="auto" w={{base:"90%",lg:"40%"}} textAlign={"start"}>
      <Heading color="#43A6AC" textDecoration={"underline"} pb="10px">{product.name}</Heading>
      
      <Table>
  <Tbody>
    <Tr>
      <Td fontWeight={500}>Suitable:</Td>
      <Td>{product.suitable}</Td>
    </Tr>
    <Tr>
      <Td fontWeight={500}>Chair Material:</Td>
      <Td>{product.chairmaterial}</Td>
    </Tr>
    <Tr>
      <Td fontWeight={500}>Material:</Td>
      <Td>{product.Material}</Td>
    </Tr>
    <Tr>
      <Td fontWeight={500}>Type:</Td>
      <Td>{product.Type}</Td>
    </Tr>
    <Tr>
      <Td fontWeight={500}>Size:</Td>
      <Td>{product.size}</Td>
    </Tr>
    <Tr>
      <Td fontWeight={500}>Footrest:</Td>
      <Td>{product.footrest}</Td>
    </Tr>
    <Tr>
      <Td fontWeight={500}>Color:</Td>
      <Td>{product.Color}</Td>
    </Tr>
    <Tr>
      <Td fontWeight={500}>Warranty:</Td>
      <Td>{product.warranty}</Td>
    </Tr>
  </Tbody>
</Table>
      <Text fontWeight={400}>{product.discription}</Text>
      {/* Other details */}


      <Flex justifyContent={"space-between"} fontSize={"30px"}>
      <Text fontWeight={500}>Price: ₹{product.listingPrice}</Text>
      <Text color={"gray"} fontWeight={500} textDecoration={"line-through"}>MRP: ₹{product.mrp}</Text>
      </Flex>
      </Box>


{/* form  */}
<Box w={{base:"90%",lg:"25%"}} m="auto">
<form onSubmit={handleSubmit}>
    
        <Box >
        <FormControl isRequired>
          <FormLabel>First Name</FormLabel>
          <Input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </FormControl>
       
        <FormControl isRequired>
          <FormLabel>Product</FormLabel>
          <Input
            type="text"
            name="email"
            value={product.name}
            onChange={handleChange}
          />
        </FormControl>
        </Box>

        <Box >

        <FormControl isRequired>
          <FormLabel>Number</FormLabel>

          <Input
            type="number"
            name="number"
            value={formData.number}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Price</FormLabel>

          <Input
            type="text"
            name="price"
            value={product.listingPrice}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>State</FormLabel>
          <Select
            name="state"
            value={formData.state}
            onChange={handleChange}
            placeholder="Select State"
            isRequired
          >
            <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
            <option value="Andhra Pradesh">Andhra Pradesh</option>
            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
            <option value="Assam">Assam</option>
            <option value="Bihar">Bihar</option>
            <option value="Chandigarh">Chandigarh</option>
            <option value="Chhattisgarh">Chhattisgarh</option>
            <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
            <option value="Delhi">Delhi</option>
            <option value="Goa">Goa</option>
            <option value="Gujarat">Gujarat</option>
            <option value="Haryana">Haryana</option>
            <option value="Himachal Pradesh">Himachal Pradesh</option>
            <option value="Jammu and Kashmir">Jammu and Kashmir</option>
            <option value="Jharkhand">Jharkhand</option>
            <option value="Karnataka">Karnataka</option>
            <option value="Kerala">Kerala</option>
            <option value="Ladakh">Ladakh</option>
            <option value="Lakshadweep">Lakshadweep</option>
            <option value="Madhya Pradesh">Madhya Pradesh</option>
            <option value="Maharashtra">Maharashtra</option>
            <option value="Manipur">Manipur</option>
            <option value="Meghalaya">Meghalaya</option>
            <option value="Mizoram">Mizoram</option>
            <option value="Nagaland">Nagaland</option>
            <option value="Odisha">Odisha</option>
            <option value="Puducherry">Puducherry</option>
            <option value="Punjab">Punjab</option>
            <option value="Rajasthan">Rajasthan</option>
            <option value="Sikkim">Sikkim</option>
            <option value="Tamil Nadu">Tamil Nadu</option>
            <option value="Telangana">Telangana</option>
            <option value="Tripura">Tripura</option>
            <option value="Uttar Pradesh">Uttar Pradesh</option>
            <option value="Uttarakhand">Uttarakhand</option>
            <option value="West Bengal">West Bengal</option>
          </Select>
        </FormControl>
        </Box>
       
        <Box mt="20px">
          <Button bg="#43A4AC" color={"white"} type="submit">
            Order
          </Button>
        </Box>
     
    </form>
    </Box>



    </Flex>
    </Box>
  );
};

export default ProductDetails;
