import React from 'react'
import { Route, Routes } from 'react-router-dom'


import Home from '../Components/Home'
import { Dashboard } from '../Components/Dashboard'
import { About } from '../Components/About'
import Login from '../Components/Login'
import { Contact } from '../Components/Contact'
import Private from '../Components/PrivateRoute'
import Product from '../Components/Products'
import ProductDetails from '../Components/Productdetails'
import Sampoo from '../Components/Sampoo'


export const AllRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/service" element={<Contact/>} />
        <Route path="/allproducts" element={<Product/>} />
        <Route path='/productdetails' element={<ProductDetails/>}/>
        <Route path='/sampoo' element={<Sampoo/>}/>

        
        <Route path='/dashboard' element={<Private><Dashboard/></Private>    } />
       

    </Routes>
  )
}