import React from 'react';
import {
  Flex,
  Box,
  Image,
  Badge,
  useColorModeValue,
  Icon,
  chakra,
  Tooltip,
  SimpleGrid,
  Heading,
  Text,
} from '@chakra-ui/react';
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';
import Slider from './Slider';
import Client from './Client';
import {SiNativescript} from "react-icons/si"
import {BsPeopleFill,BsFillBuildingsFill,BsFillFileBarGraphFill,BsGlobeCentralSouthAsia} from "react-icons/bs"
import {VscLaw} from "react-icons/vsc"
import {GrNotes} from "react-icons/gr"
import { useNavigate } from 'react-router-dom';

const data = [
  {
    isNew: true,
    imageURL: 'https://www.salonfurniture.co.uk/assets/images/zoom/salon-chair-salon-image-crystal-002.jpg',
    // name: 'chair 1',
    price: 29.99,
    rating: 4.5,
    numReviews: 170,
  },
  {
    isNew: false,
    imageURL: 'https://www.salonequipmentcentre.co.uk/site/assets/images/uploads/13968_110591_0b0a95e39b63_o.png',
    // name: 'chair 2',
    price: 39.99,
    rating: 4.2,
    numReviews: 152,
  },  {
    isNew: true,
    imageURL: 'https://rukminim2.flixcart.com/image/832/832/xif0q/shampoo-styling-chair/f/l/a/40-chakra-556-camfortcrown-original-imagtszzjse8567e.jpeg?q=70',
    // name: 'chair 1',
    // price: 29.99,
    rating: 4.5,
    numReviews: 120,
  },
  {
    isNew: false,
    imageURL: 'https://rukminim2.flixcart.com/image/832/832/xif0q/shampoo-styling-chair/r/1/r/60-linus01-linus-original-imagph9zs5szafqf.jpeg?q=70',
    // name: 'chair 2',
    price: 39.99,
    rating: 4.2,
    numReviews: 115,
  },
  // Add more products with their respective data
];

function Rating({ rating, numReviews }) {
  return (
    <Box display="flex" alignItems="center">
      {Array(5)
        .fill('')
        .map((_, i) => {
          const roundedRating = Math.round(rating * 2) / 2;
          if (roundedRating - i >= 1) {
            return (
              <BsStarFill
                key={i}
                style={{ marginLeft: '1' }}
                color={i < rating ? 'teal.500' : 'gray.300'}
              />
            );
          }
          if (roundedRating - i === 0.5) {
            return <BsStarHalf key={i} style={{ marginLeft: '1' }} />;
          }
          return <BsStar key={i} style={{ marginLeft: '1' }} />;
        })}
      <Box as="span" ml="2" color="gray.600" fontSize="sm">
        {numReviews} review{numReviews !== 1 ? 's' : ''}
      </Box>
    </Box>
  );
}

function ProductCard({ product }) {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      maxW="sm"
      borderWidth="1px"
      rounded="lg"
      shadow="lg"
      position="relative"
    >
      {product.isNew ? (
        <Badge
          rounded="full"
          px="2"
          fontSize="0.8em"
          colorScheme="red"
          position="absolute"
          top={2}
          right={2}
        >
          New
        </Badge>
      ) : null}

      <Image h="300px" w="100%" src={product.imageURL} alt={`Picture of ${product.name}`} roundedTop="lg" />

      <Box p="6">
        <Flex mt="1" justifyContent="space-between" alignContent="center">
          <Box
            fontSize="2xl"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {product.name}
          </Box>
        </Flex>

        <Flex justifyContent="space-between" alignContent="center">
          <Rating rating={product.rating} numReviews={product.numReviews} />
         
        </Flex>
      </Box>
    </Box>
  );
}

function Home() {
const nav=useNavigate()

const handleNav=()=>{
  nav("/allproducts")
}


  return (
    <Box>
         
     <Slider/>

   









    {/* j ---------------------------------- */}
   
    <Box>
        <Heading textDecoration={"underline"} mb="20px" mt="10px"> Salon Chair And Shampoo Station </Heading>
    
    <SimpleGrid columns={[1,2,3,4]} gap={"20px"} w={{base:"90%",md:"90%",lg:"95%"}} m="auto" >
      {data.map((product, index) => (
        <Box cursor={"pointer"} onClick={handleNav}>
        <ProductCard  key={index} product={product} />
        </Box>
      ))}
    </SimpleGrid>
    </Box>


  {/* -------------------------about-------------------------------  */}








  <Box>

<Heading textDecoration={"underline"} textDecorationColor={'#44A3AC'} mb="50px" mt="40px">About MikeaInfo Global Enterprises</Heading>

<SimpleGrid columns={[1,2,3]} rowGap={"30px"} m="auto" w="90%">
<Flex>
  <Box  border={"2px solid black"} p="10px" borderRadius={"50%"}>
    <Text fontSize={"40px"} >{<SiNativescript/>}</Text>
  </Box>
  <Box ml="20px" textAlign={"start"}>
    <Text  fontWeight={600} >Nature of Business</Text>
    <Text>Exporter and Manufacturer</Text>
  
  </Box>
</Flex>

{/* 2  */}
<Flex>
  <Box  border={"2px solid black"} p="10px" borderRadius={"50%"}>
    <Text fontSize={"40px"} >{<BsPeopleFill/>}</Text>
  </Box>
  <Box ml="20px" textAlign={"start"}>
    <Text  fontWeight={600} >Total Number of Employees</Text>
    <Text>26 to 50 People</Text>
  
  </Box>
</Flex>

{/* 3  */}
<Flex>
  <Box  border={"2px solid black"} p="10px" borderRadius={"50%"}>
    <Text fontSize={"40px"} >{<BsFillBuildingsFill/>}</Text>
  </Box>
  <Box ml="20px" textAlign={"start"}>
    <Text  fontWeight={600} >Year of Establishment</Text>
    <Text>2020</Text>
  
  </Box>
</Flex>

{/* 4  */}
<Flex>
  <Box  border={"2px solid black"} p="10px" borderRadius={"50%"}>
    <Text fontSize={"40px"} >{<VscLaw/>}</Text>
  </Box>
  <Box ml="20px" textAlign={"start"}>
    <Text  fontWeight={600} >Legal Status of Firm</Text>
    <Text>Individual - Proprietor</Text>
  
  </Box>
</Flex>
{/* 5 */}


<Flex>
  <Box  border={"2px solid black"} p="10px" borderRadius={"50%"}>
    <Text fontSize={"40px"} >{<BsFillFileBarGraphFill/>}</Text>
  </Box>
  <Box ml="20px" textAlign={"start"}>
    <Text  fontWeight={600} >Annual Turnover</Text>
    <Text>Upto Rs. 50 Lakh</Text>
  
  </Box>
</Flex>

{/* 6 */}
<Flex>
  <Box  border={"2px solid black"} p="10px" borderRadius={"50%"}>
    <Text fontSize={"40px"} >{<BsGlobeCentralSouthAsia/>}</Text>
  </Box>
  <Box ml="20px" textAlign={"start"}>
    <Text  fontWeight={600} >Import Export Code (IEC)</Text>
    <Text>EPMPK*****</Text>
  
  </Box>
</Flex>


{/* 7 */}

<Flex>
  <Box  border={"2px solid black"} p="10px" borderRadius={"50%"}>
    <Text fontSize={"40px"} >{<GrNotes/>}</Text>
  </Box>
  <Box ml="20px" textAlign={"start"}>
    <Text  fontWeight={600} >GST No.</Text>
    <Text> 07AQDPP3898G2ZD</Text>
  
  </Box>
</Flex>

</SimpleGrid>


<Box w={{base:'90%',lg:"70%"}} m="auto" fontWeight={600} textAlign={"justify"}>We specialize in manufacturing and wholesaling a wide range of salon and parlour furniture, including chairs, beds, wooden cabinets, shampoo stations, salon steamers, indoor sofas, ceramic pedicure tubs, salon fiber trolleys, salon wash basins, and more. Additionally, we offer top-notch salon and office interior designing services to enhance your space."</Box>

    </Box>

{/*============================---------- jaajaj =================---------------- */}
<Flex justifyContent={"space-around"} pt="20px" mt="20px" pb="40px" bgColor={"#44A3AC"} direction={{base:"column",md:"row",lg:"row"}}>
  <Box w={{base:"90%",md:"40%",lg:"40%"}} m="auto" textAlign={"start"} fontWeight={500}>
    <Heading textAlign={"center"} mb="20px" textDecoration={"underline"}>Why Us?</Heading>
        <Text align={"justify"}mb="10px">We take pride in our distinguished position among the industry leaders, offering superior quality products. Our success is attributed to several unique factors, including</Text>
        <Text mb="5px" align={"justify"}> 🫂Our extensive distribution network ensures that our products reach customers far and wide.</Text>
        <Text mb="5px" align={"justify"}>🏘️Equipped with a spacious warehouse, we efficiently manage our inventory, ensuring product availability and swift order fulfillment.</Text>
        <Text mb="5px" align={"justify"}>⌚Our commitment to prompt delivery sets us apart, ensuring that our customers receive their orders in a timely manner.</Text>
        <Text mb="5px" align={"justify"}>👍 With a client-centric approach, we prioritize the needs and satisfaction of our clients, fostering long-term relationships.</Text>

  </Box>
  <Box w={{base:"90%",md:"40%",lg:"40%"}} m="auto" textAlign={"start"} fontWeight={500}>
    <Heading textAlign={"center"} mb="20px" textDecoration={"underline"}>Customer Satisfaction</Heading>
        <Text align={"justify"}mb="10px">As a client-centric organization, we prioritize excellence at every level to ensure that our services are of the highest quality, credible, and efficient. Our commitment to providing exceptional services extends to offering various modes of service delivery at market-leading prices. Moreover, we place paramount importance on delivering our services within the scheduled time frame, ensuring a timely and damage-free experience for our customers. Your satisfaction is our top priority, and we strive to exceed expectations in every aspect of our service delivery.</Text>
        

  </Box>
</Flex>

    <Client/>









    </Box>





  );
}

export default Home;
