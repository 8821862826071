import React from "react";
import { useEffect, useState } from "react";
import { Box, Flex, Image } from "@chakra-ui/react";


const Slider = () => {
  const [counter, setCounter] = useState(0); 

  const craouselData = [
    {
      url:"https://github.com/shwetra/assa/assets/104376252/54ef74fb-8d69-45d8-9cc5-272291a7a9b5"
    },
    {
        url:"https://github.com/shwetra/assa/assets/104376252/5d72a0d3-f4b0-4262-9214-5d5ad3162244"
      },
    {
        url:"https://github.com/shwetra/assa/assets/104376252/3efa62ad-110e-4a8e-ad10-83d8da7e8326"
      },
      {
      url:"https://github.com/shwetra/assa/assets/104376252/109a6994-01c6-4e8a-b517-0090e1caebf1"
    },{
        url:"https://github.com/shwetra/assa/assets/104376252/da812188-548a-4953-949e-294a913d1ed3"
    }
];


  useEffect(() => {
    const interval = setInterval(() => {
      if (counter === craouselData.length - 1) {
        setCounter(-1);
      }setCounter((prev) => prev + 1);
    },3000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
   
        <Box position="relative" h={{base:"200px",md:"350px",lg:"600px"}}>
          <Image
            boxSize={"100%"}
            objectFit="fill"
            src={craouselData[counter].url}
            alt=""
            width={"100%"}
            className="darken"
          />
        </Box>
       
  );
};

export default Slider;