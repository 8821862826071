import './App.css';
import { Box, } from '@chakra-ui/react';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import { AllRoutes } from './Routes/AllRoutes';

function App() {
  return (
    <div  className="App">
    <Box mb="99px">
     <Navbar/>
     </Box>
    
    <AllRoutes/>
     <Footer/>
    </div>
  );
}

export default App;
