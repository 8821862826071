import {
    Box,
    Button,
    Card,
    Flex,
    Image,
    SimpleGrid,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useBreakpointValue,
    useToast,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import { BsBoxArrowRight } from "react-icons/bs";
  import { AiOutlineLeft } from "react-icons/ai";
  import { SearchIcon } from "@chakra-ui/icons";
  import axios from "axios";
  
  export const Order = () => {
    const toast=useToast()
    const [Data, setData] = useState();
    const nav = useNavigate();
    const isSmallScreen = useBreakpointValue({ base: true, lg: false });
    const isSmalllist = useBreakpointValue({ base: false, md:false ,lg: true });
 
  
    useEffect(() => {
      fetchProjects();
    }, []);
  
    async function fetchProjects() {
      try {
        const response = await fetch(`https://main-6wsv.onrender.com/order`);
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error(error);
      }
    }
    const handleLogout = () => {
      localStorage.clear();
      nav("/");
    };
  

    const handleDelete=async(id) =>{
        try {
            const response = await axios.delete(`https://main-6wsv.onrender.com/order/${id}`); 
            fetchProjects();
            // console.log(response.data)
            toast({
              title: "Deleted Successfully",
              // description: "Our team will contact you",
              status: "success",
              position: "top",
            });
          } catch (error) {
            toast({
              title: "Something Went Wrong",
              description: "Pleace try Again🙆‍♂️",
              status: "error",
              position: "top",
            });
          }
    }
   
  
  
  
  
  
    return (
      <Box>
        {/* image box */}
        <Box
          h={{ base: "100px", lg: "150px" }}
          objectFit="cover"
          borderBottomLeftRadius={{ base: "85px", lg: "85px" }}
          w="100%"
          backgroundImage="url(https://github.com/shwetra/assa/assets/104376252/41e2b0cf-a145-4fe1-afb8-123e91fc185f)"
          backgroundSize="cover"
        >
          <Image
            h="70px"
            borderRadius={"20px"}
            display={isSmallScreen ? "none" : "block"}
            m="auto"
            pt="30px"
            src="https://github.com/shwetra/assa/assets/104376252/5ed37595-af36-409a-adf7-0ebaee1ec7dd"
            alt=""
            srcSet=""
          />
  
          <Flex
            justifyContent={"space-between"}
            mt={{ base: "", lg: "-12" }}
            pt={{ base: "7" }}
          >
            <Button
              leftIcon={<AiOutlineLeft />}
              color={"white"}
              fontWeight={"bold"}
              variant="ghost"
              _hover={{ color: "white" }}
            >
              All Order List
            </Button>
  
            {isSmallScreen && (
              <Button
                bg="transparent"
                fontWeight="bold"
                onClick={handleLogout}
                variant="ghost"
                leftIcon={<BsBoxArrowRight style={{ fontSize: "28px" }} />}
                color="white"
              >
                {/* <Image h="30px" src="https://github.com/shwetra/assa/assets/104376252/e99dd9c8-5bba-41a8-b398-39c571b9b906" alt="User" /> */}
              </Button>
            )}
          </Flex>
        </Box>
  
        <Card
          h="100vh"
          ml={{base:"10px",lg:"30px"}}
          mr="10px"
          mt={{ base: "0", lg: "-20px" }}
          borderRadius={"15px"}
          style={{ boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px" }}
        >
        
          {/* lislbox */}
          {isSmalllist && (
          <Box w="100%" h="40vh">
            <Table size="md" variant="simple">
              <Thead bgColor="blue.100">
                <Tr>
                  <Th>Name</Th>
                  <Th>Product</Th>
                  <Th>Number</Th>
                  <Th>State</Th> 
                  <Th>Delete</Th>     
                </Tr>
              </Thead>
              <Tbody borderBottom="1.2px solid gray" bgColor="white">
              {Data && Data.map((e) => (
                    <Tr>
                    
                      <Td fontWeight={600}>{e.firstName}</Td>
                      <Td fontWeight={600}>{e.product}</Td>
                      <Td fontWeight={600}>{e.number}</Td>
                      <Td fontWeight={600}>{e.state}</Td>
                     <Td>
                        <Button onClick={()=>handleDelete(e._id)}>Delete</Button>
                     </Td>
                   
                      
                     
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            
       
          </Box>
  )}
  
  {/* card for smallscreen */}
  { ! isSmalllist && (
      <SimpleGrid columns={[1,2]} spacing={5}>
          {Data &&
    Data.map((e) => (
  <Box textAlign={"start"} w="330px" br="17px" h="300px" borderRadius={"15px"} m="auto"  style={{ boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px" }}>

                      <Text fontWeight={550} fontSize={"20px"} p="5px">Name : {e.firstName}</Text>                     
                      <Text fontWeight={550} fontSize={"20px"}>Email : {e.email}</Text>
                      <Text fontWeight={550} fontSize={"20px"}>Number : {e.number}</Text>
                      <Text fontWeight={550} fontSize={"20px"} p="5px" >State : {e.state}</Text>
                     <Box display={"flex"} justifyContent={"center"}>  <Button onClick={()=>handleDelete(e._id)}  mt='20px'>Delete</Button></Box>
                     
                     
                     
  
  </Box>
  ))}
  
  </SimpleGrid>
  
  )}
  
  
  
  
        </Card>
  
        
      </Box>
    );
  };