import React from 'react';
import {
  Box,
  Flex,
  Spacer,
  chakra,
  HStack,
  Button,
  Select,
  IconButton,
  useDisclosure,
  Image,
  Text,
} from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import { FaUser } from 'react-icons/fa';

const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();

  const handleCourseChange = (event) => {
    const coursePath = event.target.value;
    navigate(coursePath);
    
  };

  return (
    <Box position="fixed" top={0} left={0} right={0} zIndex={999}>
      <Box color="#f28524" fontWeight={600} bg="#2A86C6">
        <Text as="span" color="white" mr="10px">
          Welcome to
        </Text >
        MikeaInfo Global Enterprises
      </Box>
      <Box bg="white" h="80px" w="100%">
        <Flex alignItems="center" justifyContent="space-between">
          <Link to="/">
            <Image
              h="80px"
              ml="30px"
              src="https://github.com/shwetra/assa/assets/104376252/5ed37595-af36-409a-adf7-0ebaee1ec7dd"
              alt="Logo"
            />
          </Link>
          <Flex
            display={{ base: 'none', md: 'none', lg: 'flex' }}
            justifyContent="space-between"
            w="80%"
          >
            <Flex justifyContent="space-around" gap="40px" pt="15px" fontWeight={600}>
              <Link to="/">
                <Text _hover={{ color: '#2A86C6', borderBottom: '2px solid #2A86C6' }}>
                  Home
                </Text>
              </Link>
             
              <Select
                fontWeight={600}
                w="120px"
                placeholder="Products"
                _hover={{ border: 'none' }}
                border="none"
                mt="-10px"
                onChange={handleCourseChange}
                value={location.pathname}
              >
            <option value="/allproducts">Salon and Parlour Chair</option>
            <option value="/sampoo">Shampoo Station</option>
            <option value="/allproducts">Parlour Chair</option>
            <option value="/allproducts">Salon Chair</option>
              </Select>

              <Link to="/service">
                <Text _hover={{ color: '#2A86C6', borderBottom: '2px solid #2A86C6' }}>
                Services


                </Text>
              </Link>


              <Link to="/about">
                <Text _hover={{ color: '#2A86C6', borderBottom: '2px solid #2A86C6' }}>
                  About Us
                </Text>
              </Link>
             
              <Link to="/contact">
                <Text _hover={{ color: '#2A86C6', borderBottom: '2px solid #2A86C6' }}>
                  Contact
                </Text>
              </Link>
             
            </Flex>
            <Link to="/login">
              <Button
                mr="30px"
                mt="5px"
                _hover={{ bg: 'transparent', color: '#2A86C6' }}
                leftIcon={<FaUser />}
                size="md"
              >
                Staff Login
              </Button>
            </Link>
          </Flex>
          <IconButton
            icon={<HamburgerIcon />}
            mr="10px"
            color="black"
            bg="transparent"
            aria-label="Open Menu"
            display={{ base: 'block', md: 'block', lg: 'none' }}
            onClick={onToggle}
          />
        </Flex>
        <HStack
          spacing={6}
          display={{ base: isOpen ? 'flex' : 'none', md: isOpen ? 'flex' : 'none' }}
          pl="30px"
          bg="white"
          flexDirection="column"
          fontWeight={600}
          alignItems="start"
        >
          <Link to="/" onClick={onToggle}>
            <Text _hover={{ color: '#f28524', borderBottom: '2px solid #f28524' }}>Home</Text>
          </Link>
          <Link to="/about" onClick={onToggle}>
            <Text _hover={{ color: '#f28524', borderBottom: '2px solid #f28524' }}>About Us</Text>
          </Link>
          <Select
            fontWeight={600}
            ml="-15px"
            placeholder="Products"
            _hover={{ border: 'none' }}
            border="none"
            mt="-10px"
            onChange={handleCourseChange}
            
            
          >
            {/* Add your products options here */}
            <option  value="/allproducts">Salon and Parlour Chair</option>
            <option  value="/allproducts">Shampoo Station</option>
            <option  value="/allproducts">Parlour Chair</option>
            <option  value="/allproducts">Salon Chair</option>
          </Select>
         
          <Link to="/contact" onClick={onToggle}>
            <Text _hover={{ color: '#f28524', borderBottom: '2px solid #f28524' }}>Contact</Text>
          </Link>
         
          <Link to="/login" onClick={onToggle}>
            <Button
              ml="-20px"
              bg="transparent"
              mr="30px"
              mt="5px"
              _hover={{ bg: 'transparent', color: '#f28524' }}
              leftIcon={<FaUser />}
              size="md"
            >
              Staff Login
            </Button>
          </Link>
        </HStack>
      </Box>
    </Box>
  );
};

export default Navbar;
