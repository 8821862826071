import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Box,
  Select,
  Heading,
  Flex,
  Image,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const Contact = () => {
  const nav=useNavigate()
  const toast = useToast();
    const [formData, setFormData] = useState({
        firstName: '',
        email: '',
        state: '',
        number: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };
    
      

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const res=await axios.post('https://main-6wsv.onrender.com/contact', formData)
          
          toast({
            title: "ThankYou",
            description: "Our team will contact you",
            status: "success",
            position: "top",
          });
        } catch (error) {
          toast({
            title: "Something Went Wrong",
            description: "Pleace try Again🙆‍♂️",
            status: "error",
            position: "top",
          });
        }
       
      };
      
  return (
    <Box >
        <Box h={{base:"370",md:"600px",lg:"70vh"}} bgSize={{base:"contain",lg:"cover"}} m="auto" bgImage={"https://github.com/shwetra/assa/assets/104376252/4cfea175-b84f-441e-b1ae-66b6a1c29dec"}bgRepeat={"no-repeat"} w="100%">
            <Heading  pt={{base:"160px",md:"320px",lg:"200px"}} color={"white"} fontSize={{base:"20px",md:"30px",lg:"36px"}}>Welcome to MikeaInfo Global Enterprises</Heading>
        </Box>


<Box  mt="-120px" >
    <Box  w={{base:"100%",md:"70%",lg:"60%"}} m="auto"><Image h="50px" borderTopRightRadius={"100px"} src='https://github.com/shwetra/assa/assets/104376252/512401b3-2fe8-4c65-8a30-a0c99a6ac673'/> </Box>
<form onSubmit={handleSubmit}>
     <Flex bg={"white"} border="2px" borderRadius={"20px"} borderTopLeftRadius={"0px"} w={{base:"100%",md:"70%",lg:"60%"}} gap={"40px"} p="30px" m={"auto"} mb="40px" direction={{base:"column",md:"row",lg:"row"}}>
        <Box w={{base:"100%",md:"50%",lg:"50%"}} >
        <FormControl isRequired>
          <FormLabel>First Name</FormLabel>
          <Input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </FormControl>
       
        <FormControl isRequired>
          <FormLabel>Email ID</FormLabel>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </FormControl>
        </Box>

        <Box w={{base:"100%",md:"50%",lg:"50%"}}>

        <FormControl isRequired>
          <FormLabel>Number</FormLabel>

          <Input
            type="number"
            name="number"
            value={formData.number}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>State</FormLabel>
          <Select
            name="state"
            value={formData.state}
            onChange={handleChange}
            placeholder="Select State"
            isRequired
          >
            <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
            <option value="Andhra Pradesh">Andhra Pradesh</option>
            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
            <option value="Assam">Assam</option>
            <option value="Bihar">Bihar</option>
            <option value="Chandigarh">Chandigarh</option>
            <option value="Chhattisgarh">Chhattisgarh</option>
            <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
            <option value="Delhi">Delhi</option>
            <option value="Goa">Goa</option>
            <option value="Gujarat">Gujarat</option>
            <option value="Haryana">Haryana</option>
            <option value="Himachal Pradesh">Himachal Pradesh</option>
            <option value="Jammu and Kashmir">Jammu and Kashmir</option>
            <option value="Jharkhand">Jharkhand</option>
            <option value="Karnataka">Karnataka</option>
            <option value="Kerala">Kerala</option>
            <option value="Ladakh">Ladakh</option>
            <option value="Lakshadweep">Lakshadweep</option>
            <option value="Madhya Pradesh">Madhya Pradesh</option>
            <option value="Maharashtra">Maharashtra</option>
            <option value="Manipur">Manipur</option>
            <option value="Meghalaya">Meghalaya</option>
            <option value="Mizoram">Mizoram</option>
            <option value="Nagaland">Nagaland</option>
            <option value="Odisha">Odisha</option>
            <option value="Puducherry">Puducherry</option>
            <option value="Punjab">Punjab</option>
            <option value="Rajasthan">Rajasthan</option>
            <option value="Sikkim">Sikkim</option>
            <option value="Tamil Nadu">Tamil Nadu</option>
            <option value="Telangana">Telangana</option>
            <option value="Tripura">Tripura</option>
            <option value="Uttar Pradesh">Uttar Pradesh</option>
            <option value="Uttarakhand">Uttarakhand</option>
            <option value="West Bengal">West Bengal</option>
          </Select>
        </FormControl>
        </Box>
        </Flex>
        <Box>
          <Button bg="#43A4AC" color={"white"} type="submit">
            Submit
          </Button>
        </Box>
     
    </form>
</Box>

    </Box>
  )
}