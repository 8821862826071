




    import React from 'react';
import { ChakraProvider, Box, Grid, Heading, Text, Link, Image, SimpleGrid, Flex, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

// Your array of objects
const products = [
       
       
       
       
        {
          name: "Shampoo Station Premium Look Black Shampoo Chair",
          mrp: 28510,
          listingPrice: 12500,
          url: "https://github.com/shwetra/assa/assets/104376252/6583b596-7a5c-484c-a0e1-5a54fa7788cb",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Leather",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Plane handle 556 shampoo station",
          mrp: 27852,
          listingPrice: 12500,
          url: "https://github.com/shwetra/assa/assets/104376252/84ab2159-3665-401a-aa7e-31b3df94bacd",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Leather",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Red 556 shampoo station",
          mrp: 31000,
          listingPrice: 10258,
          url: "https://github.com/shwetra/assa/assets/104376252/ef76c762-a5c2-4698-9950-9b00b89b2ac0",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"20 Inch",
        },
        {
          name: "Mayur black 556 shampoo station",
          mrp: 32500,
          listingPrice: 14500,
          url: "https://github.com/shwetra/assa/assets/104376252/c8e7df80-7a8c-4e5c-9bd6-bc3f8cf53f2a",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Leather",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Regular Shampoo Station 94032 Shampoo Chair",
          mrp: 15200,
          listingPrice: 7505,
          url: "https://github.com/shwetra/assa/assets/104376252/5b58025d-10f3-46bc-89d1-8016922a6cac",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Manicure chair Styling Chair",
          mrp: 36000,
          listingPrice: 7500,
          url: "https://github.com/shwetra/assa/assets/104376252/e6194a92-9d40-489d-830a-ddcf8d3c6cde",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Leather",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Pink",
          size:"100 x 90 x 80cm",
          Capacity:"60kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          
        }
];

const ProductCard = ({ product }) => {
  const nav=useNavigate()

const handleClick=()=>{
nav("/productdetails", { state: { product } })
}


  return (
    <Box textAlign={"start"}  border={"2px solid black"} borderRadius="20" overflow="hidden"  m="4">
        <Image src={product.url}/>
      <Heading as="h2" size="md" mb="2">
        {product.name}
      </Heading>
      <Flex  justifyContent={"space-around"}>
      <Text fontWeight={550} mb="2">Price: ₹{product.listingPrice}</Text>
      <Text fontWeight={550} textDecoration={"line-through"} mb="2">MRP: ₹{product.mrp}</Text>
      </Flex>
      <Box display={"flex"} justifyContent={"center"}>
     <Button onClick={handleClick} mb="20px">View</Button>
     </Box>
    </Box>
  );
};

const Sampoo = () => {
  return (
    <Box>
    <Box >
      <Image src='https://github.com/shwetra/assa/assets/104376252/6a41d213-3f4e-40ef-ad63-0c5bed7d71e4'/>
    </Box>
    <Heading textDecoration={"underline"}>Chairs</Heading>
      <SimpleGrid columns={[1,2,3,4]} gap={4} p="4">
        {products.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </SimpleGrid> 
      </Box>
  );
  
};

export default Sampoo;




