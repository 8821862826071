import React from "react";
import { Box, SimpleGrid, Image, Heading } from "@chakra-ui/react";

const Client = () => {
  const Data = [
   
    {
        url:
          "https://github.com/shwetra/assa/assets/104376252/f0e3157c-b2ef-41f9-8925-2578f440eb04"
      },
    {
      url:
        "https://github.com/shwetra/assa/assets/104376252/65345555-0ee2-40f5-b414-56b2b407fbf5"
    }, {
        url:
          "https://github.com/shwetra/assa/assets/104376252/27bf400f-1a86-4260-a4a8-9a3a87ca30c6"
      },
  ];

  return (
    <Box mt="40px">
        <Heading mb="10px" mt="20px" textDecoration={"underline"}>Our Valuable Clients</Heading>
      <SimpleGrid columns={[1, 2, 3]} w="90%" m="auto" gap={"auto"}>
        {Data.map((e) => (
          <Box key={e.url} >
            <Image  src={e.url} alt="Image" />
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Client;
