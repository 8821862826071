




    import React from 'react';
import { ChakraProvider, Box, Grid, Heading, Text, Link, Image, SimpleGrid, Flex, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

// Your array of objects
const products = [
        {
          name: "D HANDLE ROYAL Black chair",
          mrp: 25999,
          listingPrice: 10029,
          url: "https://github.com/shwetra/assa/assets/104376252/855acd9a-b4a8-40d0-a8c0-6c312a9231dd",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Styling Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 80cm(LxW)",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"18 Inch",
        },
        {
          name: "Galaxy Chair Black",
          mrp: 21999,
          listingPrice: 9774,
          url: "https://github.com/shwetra/assa/assets/104376252/4aebc2a0-36ce-4fd6-ab15-cec83ddd1f91",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Black 4 diamond chair",
          mrp: 21999,
          listingPrice: 8000,
          url: "https://github.com/shwetra/assa/assets/104376252/be935778-8d44-4353-a548-e921ae5a1c9e",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Styling Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"110kg",
          footrest:"With Footrest",
          warranty:"1 Year",
        },
        {
          name: "Camera handle black chair",
          mrp: 19999,
          listingPrice: 8800,
          url: "https://github.com/shwetra/assa/assets/104376252/f1d97124-30e0-46b4-972f-52da3fa4d9cf",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Mayur Chair Black",
          mrp: 18600,
          listingPrice: 9999,
          url: "https://github.com/shwetra/assa/assets/104376252/28276d58-4461-4784-9223-1b4748fa2c24",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Galaxy  Red Chair",
          mrp: 29000,
          listingPrice: 9700,
          url: "https://github.com/shwetra/assa/assets/104376252/d34bb6fe-83a1-4502-b5e3-0327240cc8de",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          suitable:"Adults",
          Type:"Styling Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"120kg",
          footrest:"With Footrest",
          warranty:"1 Year",
          Height:"19 Inch"
        },
        {
          name: "6 Diamond Red Plane Chair   image banwao",
          mrp: 22999,
          listingPrice: 9944,
          url: "https://github.com/shwetra/assa/assets/104376252/258e2223-f513-4003-95ac-7f9eb5bf8173",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Red and black 4 diamond chair",
          mrp: 28999,
          listingPrice: 10999,
          url: "https://github.com/shwetra/assa/assets/104376252/3ea5fb0f-50d9-46c9-9a26-2c0d83a4600a",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"PU Leather",
          suitable:"Adults",
          Type:"Styling Chair",
          Material:"Stainless Steel",
          Color:"Red & Black",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"With Footrest",
          warranty:"1 Year",
        },
        {
          name: "Royal galaxy Red and black chair",
          mrp: 22099,
          listingPrice: 10000,
          url: "https://github.com/shwetra/assa/assets/104376252/c32fb7d4-18cc-4fe3-a7dd-9fded73a14ce",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Leather",
          suitable:"Adults",
          Type:"Styling Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90(LxB)",
          Capacity:"80kg",
          footrest:"With Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        // {
        //   name: "Black and red D handle chair  image nhi hai banwao",
        //   mrp: 24000,
        //   listingPrice: 9805,
        //   url: "https://www.flipkart.com/seatingsolution-styling-chair/p/itm8627860bc7bb9?pid=SSCGPHZCWU5VRYYW",
        //   discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
        //   chairmaterial:"Rexine",
        //   suitable:"Adults",
        //   Type:"Shampoo Chair",
        //   Material:"Stainless Steel",
        //   Color:"Red",
        //   size:"100 x 90 x 80cm",
        //   Capacity:"80kg",
        //   footrest:"Without Footrest",
        //   warranty:"1 Year",
        //   Height:"27 Inch",
        // },
        {
          name: "Mayur Ten colour chair",
          mrp: 24000,
          listingPrice: 10000,
          url: "https://github.com/shwetra/assa/assets/104376252/eac7a02a-cc7f-46dd-9b88-b3385b6ce8c9",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Red 4 diamond chair",
          mrp: 15000,
          listingPrice: 9999,
          url: "https://github.com/shwetra/assa/assets/104376252/5a81d8a1-ddc6-49e2-bf41-f24492f78fbc",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"PU Leather",
          suitable:"Adults",
          Type:"Styling Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"With Footrest",
          warranty:"1 Year",
        },
        {
          name: "Black and white Mayur chair",
          mrp: 25066,
          listingPrice: 9999,
          url: "https://github.com/shwetra/assa/assets/104376252/9ee434ca-7713-4aea-a1e3-c129db5e39cf",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "6 diamond black chair",
          mrp: 15000,
          listingPrice: 8000,
          url: "https://github.com/shwetra/assa/assets/104376252/8543ba32-8e78-4d47-bb42-5a5ff558f885",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Leather",
          suitable:"Adults",
          Type:"Styling Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"90kg",
          footrest:"With Footrest",
          warranty:"1 Year",
          Height:"24 Inch",
          grade:"SS310"
        },
        {
          name: "plane black salon chair",
          mrp: 14500,
          listingPrice: 8000,
          url: "https://github.com/shwetra/assa/assets/104376252/3fea85b2-0284-4f22-b8e7-d1e9b48a8633",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"PU Leather",
          suitable:"Adults",
          Type:"Styling Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"With Footrest",
          warranty:"1 Year",
        },
        {
          name: "6 diamond black chair",
          mrp: 15550,
          listingPrice: 8000,
          url: "https://github.com/shwetra/assa/assets/104376252/2c20f905-a54b-4416-8411-5c052ac80be8",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Super Royal Galaxy Black chair",
          mrp: 25000,
          listingPrice: 9500,
          url: "https://github.com/shwetra/assa/assets/104376252/45f92785-99d0-4efe-9fad-8026d484a3b4",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Royal small D handle chair",
          mrp: 25000,
          listingPrice: 9800,
          url: "https://github.com/shwetra/assa/assets/104376252/32178192-1adc-4472-a452-a89480df722a",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Apple chair ",
          mrp: 27500,
          listingPrice: 12500,
          url: "https://github.com/shwetra/assa/assets/104376252/eae19992-22ef-4744-98f7-abedfc52e4bf",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Styling Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"With Footrest",
          warranty:"1 Year",
        },
        {
          name: "12 Diamond Shampoo Station Black Shampoo Chair",
          mrp: 24500,
          listingPrice: 12500,
          url: "https://github.com/shwetra/assa/assets/104376252/935b156d-24a8-466c-bd2d-418bdfba6f99",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Shampoo Station Premium Look Black Shampoo Chair",
          mrp: 28510,
          listingPrice: 12500,
          url: "https://github.com/shwetra/assa/assets/104376252/6583b596-7a5c-484c-a0e1-5a54fa7788cb",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Plane handle 556 shampoo station",
          mrp: 27852,
          listingPrice: 12500,
          url: "https://github.com/shwetra/assa/assets/104376252/84ab2159-3665-401a-aa7e-31b3df94bacd",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Red 556 shampoo station",
          mrp: 31000,
          listingPrice: 10258,
          url: "https://github.com/shwetra/assa/assets/104376252/ef76c762-a5c2-4698-9950-9b00b89b2ac0",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Red",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Mayur black 556 shampoo station",
          mrp: 32500,
          listingPrice: 14500,
          url: "https://github.com/shwetra/assa/assets/104376252/c8e7df80-7a8c-4e5c-9bd6-bc3f8cf53f2a",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Regular Shampoo Station 94032 Shampoo Chair",
          mrp: 15200,
          listingPrice: 7505,
          url: "https://github.com/shwetra/assa/assets/104376252/5b58025d-10f3-46bc-89d1-8016922a6cac",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Black",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
          Height:"27 Inch",
        },
        {
          name: "Burfi  white and brown Salon",
          mrp: 24520,
          listingPrice: 12500,
          url: "https://github.com/shwetra/assa/assets/104376252/f4f7e3bb-7540-4293-93c0-aafb1a3bda33",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Rexine",
          suitable:"Adults",
          Type:"Styling Chair",
          Material:"Stainless Steel",
          Color:"Cream",
          size:"100 x 90 x 80cm",
          Capacity:"80kg",
          footrest:"With Footrest",
          warranty:"1 Year",
          Height:"4.5 Feet",
        },
        {
          name: "Manicure chair Styling Chair",
          mrp: 36000,
          listingPrice: 7500,
          url: "https://github.com/shwetra/assa/assets/104376252/e6194a92-9d40-489d-830a-ddcf8d3c6cde",
          discription:"Make It Easier For Cosmetologists To Cut And Style All Lengths Of Hair.",
          chairmaterial:"Leather",
          suitable:"Adults",
          Type:"Shampoo Chair",
          Material:"Stainless Steel",
          Color:"Pink",
          size:"100 x 90 x 80cm",
          Capacity:"60kg",
          footrest:"Without Footrest",
          warranty:"1 Year",
        }
];

const ProductCard = ({ product }) => {
  const nav=useNavigate()

const handleClick=()=>{
nav("/productdetails", { state: { product } })
}


  return (
    <Box textAlign={"start"}  border={"2px solid black"} borderRadius="20" overflow="hidden"  m="4">
        <Image  src={product.url}/>
      <Heading as="h2" size="md" mb="2">
        {product.name}
      </Heading>
      <Flex  justifyContent={"space-around"}>
      <Text fontWeight={550} mb="2">Price: ₹{product.listingPrice}</Text>
      <Text fontWeight={550} textDecoration={"line-through"} mb="2">MRP: ₹{product.mrp}</Text>
      </Flex>
      <Box display={"flex"} justifyContent={"center"}>
     <Button onClick={handleClick} mb="20px">View</Button>
     </Box>
    </Box>
  );
};

const Product = () => {
  return (
    <Box>
    <Box >
      <Image src='https://github.com/shwetra/assa/assets/104376252/6a41d213-3f4e-40ef-ad63-0c5bed7d71e4'/>
    </Box>
    <Heading textDecoration={"underline"}>Chairs</Heading>
      <SimpleGrid columns={[1,2,3,4]} gap={4} p="4">
        {products.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </SimpleGrid> 
      </Box>
  );
  
};

export default Product;




