import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { Table, Tbody, Tr, Td } from '@chakra-ui/react';

export const About = () => {
  const basicInformation = [
    { label: 'Nature of Business', value: 'Exporter and Manufacturer' },
    { label: 'Additional Business', value: 'Wholesaler, Service Provider' },
    { label: 'Company CEO', value: 'Mahesh Parsad' },
    {
      label: 'Registered Address',
      value: 'Plot No -1 Rajan Vihar Near raghubir singh school  Hastal village uttam nagar New Delhi 110059',
    },
    { label: 'Total Number of Employees', value: '26 to 50 People' },
    { label: 'Year of Establishment', value: '2017' },
    { label: 'Legal Status of Firm', value: 'Individual - Proprietor' },
    { label: 'Annual Turnover', value: 'Upto Rs. 50 Lakh' },
  ];



  return (
    <Box pb="80px">
        <Image w="100%" src="https://github.com/shwetra/assa/assets/104376252/fcfaffef-7030-4088-8d85-afddb7056578"/>


        <Box>
  <Heading textDecoration={"underline"}mb="20px">Basic Information</Heading>
<Table variant="simple">
      <Tbody>
        {basicInformation.map((info, index) => (
          <Tr key={index}>
            <Td fontWeight="bold">{info.label}</Td>
            <Td>{info.value}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
</Box>

        <Box w="100%" textAlign={"justify"} >
    <Flex direction={{base:"column",md:"column",lg:"row"}} >
        <Box w={{base:"90%",md:"90%",lg:"50%"}}> <Image w="full" src="https://github.com/shwetra/assa/assets/104376252/5ed37595-af36-409a-adf7-0ebaee1ec7dd" alt="Logo"  /></Box>
        <Box w={{base:"90%",md:"90%",lg:"50%"}}  m="auto">
        <Box w={{base:"100%",md:"100%",lg:"90%"}}>
        <Heading
              as="h3"
              // w="50%"
              borderBottom={"2px solid #f28524"}
              fontSize="28px"
              mb={4}
            >
              About MikeaInfo Global Enterprises
            </Heading>
            <Text fontWeight={550}>Welcome to MikeaInfo Global Enterprises, where excellence meets innovation in the realm of salon furniture. At MikeaInfo Global Enterprises, we take pride in crafting salon chairs that redefine comfort, style, and functionality. Our commitment to excellence is reflected in every piece we create, combining meticulous craftsmanship with a keen understanding of the evolving needs of the salon industry.</Text>
            <Text fontWeight={550} mt="10px" mb="10px">Established with a vision to elevate salon experiences, we specialize in the design and production of salon chairs that not only meet but exceed industry standards. Our dedication to quality is unwavering, as we utilize premium materials and cutting-edge manufacturing techniques to ensure durability and aesthetic appeal in every chair we produce.</Text>
            <Text fontWeight={550}>What sets MikeaInfo Global Enterprises apart is our passion for creating furniture that not only complements the salon environment but also enhances the overall client experience. We understand that a comfortable and aesthetically pleasing salon chair is integral to the success of any salon, and our team is committed to providing solutions that cater to the unique requirements of our clients.</Text>
        </Box>
       
        </Box>
    </Flex>
</Box>




{/* TEXT  */}
<Box  textAlign={"justify"} w="90%" m="auto">
    <Text fontWeight={550} mb="30px">As we continue on our journey of innovation, we invite you to explore the world of MikeaInfo Global Enterprises and discover how our salon chairs can transform your salon space. Join us in reimagining the future of salon furniture, where quality, design, and customer satisfaction converge to create an unparalleled salon experience.</Text>
    <Text fontWeight={550} mb="30px">At MikeaInfo Global Enterprises, our dedication to excellence extends beyond crafting salon chairs; it encompasses a comprehensive understanding of the salon and beauty industry. We are not just manufacturers; we are partners in the success of your salon business. Our team comprises skilled professionals who are passionate about bringing creativity and functionality together to design salon chairs that resonate with contemporary trends and client preferences.</Text>
    <Text fontWeight={550} mb="50px">In addition to our commitment to quality, MikeaInfo Global Enterprises is devoted to fostering sustainable practices in our manufacturing processes. We prioritize environmentally friendly materials and production methods, ensuring that our salon chairs not only meet the highest standards but also contribute to a greener future.</Text>
    <Text fontWeight={550} mb="30px">At the heart of our company is a client-centric philosophy. We value the relationships we build with salon owners, designers, and industry professionals. Our team works collaboratively to understand your unique vision, offering customized solutions that align with your brand identity and enhance the ambiance of your salon.</Text>
    <Text fontWeight={550} mb="30px">As we look towards the future, MikeaInfo Global Enterprises is poised to remain at the forefront of salon chair innovation. Our journey is not just about crafting furniture; it's about transforming spaces and creating lasting impressions. Join us in shaping the future of salon aesthetics with furniture that reflects your commitment to excellence and style.</Text>
</Box>

<Box w="80%" m="auto">
  <Heading textDecoration={"underline"} mt="20px" mb="10px">Why Us?</Heading>
  <Text align={"justify"}  >We take pride in our distinguished position among the industry leaders, offering superior quality products. Our success is attributed to several unique factors, including

🫂Our extensive distribution network ensures that our products reach customers far and wide.

🏘️Equipped with a spacious warehouse, we efficiently manage our inventory, ensuring product availability and swift order fulfillment.

⌚Our commitment to prompt delivery sets us apart, ensuring that our customers receive their orders in a timely manner.

👍 With a client-centric approach, we prioritize the needs and satisfaction of our clients, fostering long-term relationships.</Text>
</Box>



<Box w="80%" m="auto">
  <Heading textDecoration={"underline"} mb="10px" mt="20px">Customer Satisfaction</Heading>
  <Text align={"justify"}  >As a client-centric organization, we prioritize excellence at every level to ensure that our services are of the highest quality, credible, and efficient. Our commitment to providing exceptional services extends to offering various modes of service delivery at market-leading prices. Moreover, we place paramount importance on delivering our services within the scheduled time frame, ensuring a timely and damage-free experience for our customers. Your satisfaction is our top priority, and we strive to exceed expectations in every aspect of our service delivery.</Text>
</Box>



    </Box>
  )
}