import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormLabel,
    Heading,
    Image,
    Input,
    Stack,
    Text,
    useToast,
  } from "@chakra-ui/react";


  import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

  
  function Login() {
    const nav=useNavigate()
    const toast = useToast();
    const [data, setData] = useState({
      email: "",
      password: "",
      passwordno: "", 
    });
    const { email, password,passwordno } = data;
    const handleChange = (e) => {
      setData({ ...data, [e.target.name]: e.target.value });
    };
    const handleSubmit = () => {
      if (data.email === "info@mikeainfo.com" && data.password=== "mikeainfo@12345" && data.passwordno=== "maheshshiv@123") {
        localStorage.setItem("GlalEnterpes", JSON.stringify("qwertyhgfdsa"));
        toast({
          title: "Login Successfully",
          description: "Hello Mahesh Sir 🙆‍♂️",
          status: "success",
          position: "top",
        });
        nav("/dashboard");
      } else {
        toast({
          title: "Information Required",
          description: "Kindly fill all details",
          status: "error",  // Issue: Should be "error" instead of "falure"
          position: "top",
        });
      }
    };
    
  
  
  
    return (
      <Box m={"auto"} pt={"80px"} pb="70px" >
     <Flex w={{base:"90%",md:"80%",lg:'60%'}} h={{base:'auto',md:'auto',lg:'auto'}} pb="30px" m={'auto'} border={'2px solid black'} borderRadius={"20px"} direction={{base:"column",md:"row",lg:"row"}}>
        <Box  w={{base:'100%',md:'40%',lg:'30%'}} borderRadius={"19px"} > 
        <Image pt="70px" m="auto" src='https://github.com/shwetra/assa/assets/104376252/5ed37595-af36-409a-adf7-0ebaee1ec7dd'/>
        </Box>
        <Box ml="20px" w={{base:'90%',md:'40%',lg:'60%'}}>
            <Heading fontSize={"20px"} textDecoration={"underline"}>Staff Login</Heading>
            <Box>
              <FormLabel>Email:</FormLabel>
              <Input
              
               color={"black"}
                border={"2px solid gray"}
                type="email"
                name="email"
                placeholder="Username"
                value={email}
                onChange={handleChange}
              />
            </Box>
  
            <Box>
              <FormLabel>Password:</FormLabel>
              <Input
                color={"black"}
                border={"2px solid gray"}
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={handleChange}
              />
            </Box>

            <Box>
              <FormLabel>Password: 1</FormLabel>
              <Input
                color={"black"}
                border={"2px solid gray"}
                type="password"
                name="passwordno"
                placeholder="Password No 1"
                value={passwordno}
                onChange={handleChange}
              />
            </Box> 
            
            <Box mt={'10px'}>
            <Stack>
                <Checkbox colorScheme="green" defaultChecked>
                      Remeber me!
                </Checkbox>
              </Stack>
              </Box>
           
              <Box
                w={"100%"}
                display={"flex"}
                justifyContent={"center"}
                
              >
                <Box>
                  {/* Button */}
                  <Button
                    p={"5px"}
                    mt={"10px"}
                    border={"2px solid teal"}
                    w={"100px"}
                    color={"black"}
                    bg={"teal.100"}
                    fontSize={"18px"}
                    fontWeight={"600"}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
  
              </Box>
          
          </Box>
      </Flex>
  
  
      </Box>
    );
  }
  
  export default Login;