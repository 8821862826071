import {
    Box,
    Flex,
    Heading,
    Text,
    UnorderedList,
    ListItem,
    IconButton,
    SimpleGrid,
    Image,
  } from "@chakra-ui/react";
  import {
    FaFacebook,
    FaYoutube,
    FaTwitter,
    FaLinkedin,
    FaAngleRight,
    FaInstagram,
    FaPhoneAlt
  } from "react-icons/fa";
  import {MdEmail} from "react-icons/md"
import { Link } from "react-router-dom";
  
  function Footer() {
    return (
      <Box as="footer"  bg="white">  
        <SimpleGrid columns={[1, 2, 3]} w="90%" m="auto">
        <Box mx="auto" px={4} textAlign={"start"}>
            <Heading
              as="h3"
              w="50%"
              borderBottom={"2px solid #47A3AE"}
              color={"#47A3AE"}
              fontSize="xl"
              mb={4}
            >
              MikeaInfo
            </Heading>
  
           
            <Text mt="10px" fontSize={"15"} fontWeight={600} align={"justify"}>
            "At MikeaInfo Global Enterprises, we're dedicated to delivering exceptional Salon Chair solutions. With a passion for innovation and a commitment to quality, we strive to exceed your expectations in every way. Join our thriving community and explore a world of possibilities with us. We're here to empower you, one step at a time, on your journey towards excellence."
            </Text>
          </Box>



          <Box px={4} textAlign={"start"}>
            <Heading
              as="h3"
              w="50%"
              borderBottom={"2px solid #47A3AE"}
              fontSize="xl"
              color={"#47A3AE"}
              mb={4}
            >
              Useful Links
            </Heading>
            <UnorderedList listStyleType="none" pl={0}>
              <Flex>
                <Text color={"gray"} mt="4px">
                  {<FaAngleRight />}
                </Text>
                <Link to={"/"}>
                <ListItem>Home</ListItem>
                </Link>
              </Flex>
              <Flex>
                <Text color={"gray"} mt="4px">
                  {<FaAngleRight />}
                </Text>
                <Link to={"/services"}>
                <ListItem>Services</ListItem>
                </Link>
              </Flex>
              <Flex>
                <Text color={"gray"} mt="4px">
                  {<FaAngleRight />}
                </Text>
                <Link to={"/about"}>
                <ListItem>About us</ListItem>
                </Link>
              </Flex>
              <Flex>
                <Text color={"gray"} mt="4px">
                  {<FaAngleRight />}
                </Text>
                <Link to={"/contact"}>
                <ListItem>Contact</ListItem>
                </Link>
              </Flex>
             
            </UnorderedList>
          </Box>


          <Box px={4} textAlign={"start"}>
            <Heading
              w="50%"
              borderBottom={"2px solid #47A3AE"}
              as="h3"
              color={"#47A3AE"}
              fontSize="xl"
              mb={4}
            >
              Get In Touch
            </Heading>
            <Text>Plot No -1 Rajan Vihar Near raghubir singh school  Hastal village uttam nagar New Delhi 110059</Text>
            <Text>Phone: 8800617878</Text>
            <Text>Email: maheshprasad193@gmail.com</Text>
            
            <Flex mt={"20px"} >
              <IconButton
                as="a"
                href="https://www.facebook.com/profile.php?id=61554030296260&mibextid=vk8aRt"
                target="_blank"
                bg="#47A3AE"
                aria-label="Facebook"
                icon={<FaFacebook />}
                size="md"
                colorScheme="blue"
                mr={2}
              />
              <IconButton
                as="a"
                href="https://youtube.com/@ShivKumar-ql5yf?si=woEaVq408sQzLE_2"
                target="_blank"
                bg="#47A3AE"
                aria-label="YouTube"
                icon={<FaYoutube />}
                size="md"
                colorScheme="red"
                mr={2}
              />
              <IconButton
                as="a"
                bg="#47A3AE"
                href="https://x.com/mikeainfo2722?t=g__se3LuAINlQSrZVodvXA&s=08"
                target="_blank"
                aria-label="Twitter"
                icon={<FaTwitter />}
                size="md"
                colorScheme="twitter"
                mr={2}
              />
              <IconButton
                as="a"
                href="https://instagram.com/techakiras_official?igshid=MzMyNGUyNmU2YQ=="
                bg="#47A3AE"
                target="_blank"
                aria-label="LinkedIn"
                icon={<FaInstagram />}
                size="md"
                colorScheme="linkedin"
              />
            </Flex>
          </Box>
         
  
         
        </SimpleGrid>
  
        <Box w="100%" m="auto" borderTop={"1px solid black"} mt="10px" h="50px" bg="gray.100">
          <Text fontWeight={"600"}pt="10px" align={"center"}>© Copyright 2023 All rights reserved.</Text>
        </Box>


        <Box as="footer"  p={4} bg="gray.200">
      <SimpleGrid columns={[1,2,3,4]}  align="center" >
       
      <Box mt={[4, 0]}>
          <Heading fontSize="xl">Developed by</Heading>
          <Text fontWeight={550} color={"blue"} textDecoration={"underline"} fontSize={"18px"}>Shwet Rai</Text>
        </Box> 
        <Box>
          <Flex>
          <IconButton
                as="a"
                bg="#0077B5"
                href="mailto:techakiras@gmail.com"
                target="_blank"
                aria-label="Twitter"
                icon={<MdEmail/>}
                size="md"
                colorScheme="linkdin"
                mr={2}
              />
          <Text fontWeight={550} mt="10px">techakiras@gmail.com</Text>
          </Flex>
        </Box>

        <Box>
          <Flex>
          <IconButton
                as="a"
                bg="#0077B5"
                href="tel:+8287825720"
                target="_blank"
                aria-label="Twitter"
                icon={<FaPhoneAlt />}
                size="md"
                colorScheme="linkdin"
                mr={2}
              />
          <Text fontWeight={550} mt="10px">+91 8287825720</Text>
          </Flex>
        </Box>
        

        <Box mt={[4, 0]}>
          <Flex ><IconButton
                as="a"
                bg="#0077B5"
                href="https://www.linkedin.com/in/shwet-rai-16238a232/"
                target="_blank"
                aria-label="Twitter"
                icon={<FaLinkedin />}
                size="md"
                colorScheme="linkdin"
                mr={2}
              />
              <IconButton
                as="a"
                href="https://instagram.com/techakiras_official?igshid=MzMyNGUyNmU2YQ=="
                bg="#C235A7"
                target="_blank"
                aria-label="LinkedIn"
                icon={<FaInstagram />}
                size="md"
                colorScheme="linkedin"
              /></Flex>
        </Box>



      
      </SimpleGrid>
    </Box>
      </Box>
    );
  }
  
  export default Footer;
  